import React from "react";
import {
  Col,
  Container,
  Row,
  Modal,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";

import Icon from "../../../functions/Icon";
import Colors from "../../../styles/Colors";
import "./styles.css";

function ModalTempoAtraso({ ...props }) {
  const { handler_show_modal, show_modal } = props;

  return (
    <Modal
      show={show_modal}
      onHide={() => handler_show_modal(false)}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="p-0">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 20,
            paddingBottom: 6,
          }}
        >
          <div>
            <div className="modal-title">Tempo médio de atraso</div>
            <p className="subtitle-report">
              O que representa o tempo médio de atraso
            </p>
          </div>
          <div>
            <div
              className="modal-close-btn"
              onClick={() => handler_show_modal(false)}
            >
              <Icon color={Colors.darkest_gray} size={15} icon="x" />
            </div>
          </div>
        </div>

        <div className="modal-all">
          <div className="mt-4">
            <p>
              O tempo médio de atraso geral é calculado considerando todo o
              período analisado, enquanto os valores por plantão levam em conta
              apenas os atrasos dentro de cada turno.
            </p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalTempoAtraso;

import "./styles.css";
import React, { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/pt";
import { Modal, Spinner, Table } from "react-bootstrap";
import Icon from "../../functions/Icon";
import Colors from "../../styles/Colors";
import api from "../../services/api";
import { useSelector } from "react-redux";

function ModalRankingSuspensionsDetails({ ...props }) {
  const { handler_show_modal, show_modal, suspension, origin, isPause } = props;
  const unit = useSelector((state) => state.unit.selecionado);

  const [suspensionData, setSuspensionData] = useState(null);

  useEffect(() => {
    if (suspension) {
      api
        .get("dashboard/suspensions-data", {
          params: {
            ids: suspension.ids?.join(","),
            unitId: unit.id,
          },
        })
        .then((response) => {
          if (response.data) {
            setSuspensionData(response.data);
          }
        });
    }
  }, [suspension]);

  return (
    <Modal
      show={show_modal}
      onHide={() => handler_show_modal(false)}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="p-0">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 20,
            paddingBottom: 6,
          }}
        >
          <div>
            <div className="modal-title">
              {isPause ? "Detalhes da Pausa" : "Detalhes da Suspensão"}
            </div>
            <p className="subtitle-report">{suspension.name}</p>
          </div>
          <div>
            <div
              className="modal-close-btn"
              onClick={() => handler_show_modal(false)}
            >
              <Icon color={Colors.darkest_gray} size={15} icon="x" />
            </div>
          </div>
        </div>

        <div className="modal-all">
          <div className="mt-4">
            {suspensionData ? (
              <Table responsive hover className="patient-table">
                <thead>
                  <tr>
                    {!origin.includes("patient") && <th>Paciente</th>}
                    {!origin.includes("suspensionsRank") && <th>Motivo</th>}
                    <th>Suspenso em</th>
                    <th>Suspenso por</th>
                    <th>Tempo da suspensão</th>
                    <th>Reativado por</th>
                    <th>Reativado em</th>
                  </tr>
                </thead>
                <tbody>
                  {suspensionData.map((item, index) => {
                    return (
                      <tr>
                        {!origin.includes("patient") && (
                          <td>{item.patientName}</td>
                        )}
                        {!origin.includes("suspensionsRank") && (
                          <td>{item.motive}</td>
                        )}
                        <td>
                          {moment(item.initialTime)
                            .locale("pt")
                            .format("DD/MM/YYYY, HH:mm")}
                        </td>
                        <td>{item.nurseName}</td>
                        <td>{item.duration}</td>
                        <td>{item.reactivatedNurseName || "-"}</td>
                        <td>
                          {item.reactivatedTime
                            ? moment(item.reactivatedTime)
                                .locale("pt")
                                .format("DD/MM/YYYY, HH:mm")
                            : "-"}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            ) : (
              <div className="mt-3 d-flex justify-content-center">
                <Spinner animation="border" variant="primary" />
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalRankingSuspensionsDetails;

import "./styles.css";
import React, { useState } from "react";
import moment from "moment";
import { Modal } from "react-bootstrap";
import Icon from "../../functions/Icon";
import Colors from "../../styles/Colors";
import ModalRankingSuspensionsDetails from "./ModalRankingSuspensionsDetails";

function ModalRankingDiagrama({ ...props }) {
  const { handler_show_modal, show_modal, list, nameModal, origin, isPause } =
    props;
  const [selectedSuspension, setSelectedSuspension] = useState(null);
  const [showSuspensionsDetailsModal, setShowSuspensionsDetailsModal] =
    useState(false);

  return (
    <>
      {!showSuspensionsDetailsModal && (
        <Modal
          show={show_modal}
          onHide={() => handler_show_modal(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body className="p-0">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: 20,
                paddingBottom: 6,
              }}
            >
              <div>
                <div className="modal-title">{nameModal.title}</div>
                <p className="subtitle-report">{nameModal.subtitle}</p>
              </div>
              <div>
                <div
                  className="modal-close-btn"
                  onClick={() => handler_show_modal(false)}
                >
                  <Icon color={Colors.darkest_gray} size={15} icon="x" />
                </div>
              </div>
            </div>

            <div className="modal-all">
              <div className="mt-4">
                {list.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="rr-item"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setSelectedSuspension(item);
                        setShowSuspensionsDetailsModal(true);
                      }}
                    >
                      <div
                        className="d-flex align-items-center"
                        style={{
                          flexBasis: "calc(100% / 3)",
                        }}
                      >
                        <p className="rr-position">{index + 1}°</p>
                        <p className="rr-title">{item.name}</p>
                      </div>
                      <p className="rr-qtd">{item.counter}</p>
                      {item.counterTime && (
                        <p className="rr-qtd">{item.counterTime}</p>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {showSuspensionsDetailsModal && (
        <ModalRankingSuspensionsDetails
          origin={origin}
          suspension={selectedSuspension}
          handler_show_modal={setShowSuspensionsDetailsModal}
          show_modal={showSuspensionsDetailsModal}
          isPause={isPause}
        />
      )}
    </>
  );
}

export default ModalRankingDiagrama;

import React, { useEffect, useState } from "react";
import Colors from "../../../../../../../styles/Colors";
import moment from "moment";
import { Col, Row, Table } from "react-bootstrap";
import {
  secondsToFullTime,
  secondsToHoursMinutes,
} from "../../../../../../../functions/utils";
import ModalRankingDiagrama from "../../../../../../Relatorios/ModalRanking";

var nameModal = {};
var listRanking = [];

function PausesRanking(props) {
  const { pauses } = props;

  const [generalPauses, setGeneralPauses] = useState({
    totalTime: 0,
    totalPauses: 0,
  });
  const [showModalPauses, setShowModalPauses] = useState(false);

  function convertPauseMotivesToList(input) {
    const output = [];

    for (const pause of input) {
      const item = {
        ids: pause.ids,
        name: pause.pauseKey,
        counter: pause.totalPauses,
        counterTime: secondsToFullTime(pause.totalTime),
      };
      output.push(item);
    }

    return output;
  }

  useEffect(() => {
    if (pauses) {
      let totalTime = 0;
      let totalPauses = 0;
      for (const pause of pauses) {
        totalTime += pause.totalTime;
        totalPauses += pause.totalPauses;
      }
      setGeneralPauses({
        totalTime,
        totalPauses,
      });
    }
  }, [pauses]);

  return (
    <>
      {pauses && (
        <Row>
          <Col lg={8} sm={12}>
            <>
              <div className="mt-4">
                <Table className="borderless" responsive>
                  <thead>
                    <tr>
                      <th>Motivo</th>
                      <th>Tempo</th>
                      <th>Qntd.</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pauses.map((value, index) => {
                      if (index > 2) {
                        return;
                      }
                      return (
                        <tr>
                          <td>
                            <div
                              key={index}
                              className="rr-item"
                              style={{
                                border: "none",
                                padding: "0px",
                              }}
                            >
                              <div className="d-flex align-items-center">
                                <p className="rr-position">{index + 1}°</p>
                                <p className="rr-title">{value.pauseKey}</p>
                              </div>
                            </div>
                          </td>
                          <td>{secondsToFullTime(value.totalTime)}</td>
                          <td>{value.totalPauses}</td>
                        </tr>
                      );
                    })}
                  </tbody>{" "}
                </Table>
              </div>

              <div className="mt-4 mb-4 d-flex justify-content-center">
                <button
                  className="btn-visu"
                  onClick={() => {
                    listRanking = convertPauseMotivesToList(pauses);
                    nameModal = {
                      title: "Ranking avisos pausados",
                      subtitle: "Motivos da pausa dos avisos",
                    };
                    setShowModalPauses(true);
                  }}
                >
                  Visualizar lista completa
                </button>
              </div>
            </>
          </Col>
          <Col
            lg={4}
            sm={12}
            className="d-flex justify-content-center align-items-center"
          >
            <div
              className="card-report-info overview-suspensions-ranking"
              style={{
                minHeight: "250px",
              }}
            >
              <Row
                style={{
                  padding: "30px",
                }}
              >
                <Col sm={12} className="mb-3">
                  <span className="report-value-suspensions">
                    {generalPauses.totalPauses}
                  </span>
                  <span className="report-desc-suspensions">
                    Qntd. total de pausas
                  </span>
                </Col>
                <Col sm={12}>
                  <span className="report-value-suspensions">
                    {secondsToHoursMinutes(generalPauses.totalTime)}
                  </span>
                  <span className="report-desc-suspensions">
                    Tempo total de pausa
                  </span>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      )}
      {showModalPauses && (
        <ModalRankingDiagrama
          handler_show_modal={setShowModalPauses}
          show_modal={showModalPauses}
          list={listRanking}
          nameModal={nameModal}
          origin={["patient", "pausesRank"]}
          isPause={true}
        />
      )}
    </>
  );
}

export default PausesRanking;
